import anime from 'animejs';
import { oengus } from '../classes/oengus';
import { infoBar } from '../classes/infobar'
import { strapi } from '../classes/strapi';

var dayjs = require('dayjs');

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration);
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);
require('dayjs/locale/it')

let flagRenderer = {
    flagOutExtent: 220,
    linearOutDuration: 550,
    skewProperties: {
        value: 18.5,
        easing: 'steps(1)',
        duration: 1,
    },
    skewPropertiesNegative: {
        value: -18.5,
        easing: 'steps(1)',
        duration: 1,
    },
    nextRunElement: '#flag-piece-red>.long-flag>#next-run',
    futureRuns: [],

    futureRunAnimationDuration: 200,
    futureRunAnimationDelay: 20000,

    logoCrossFadeDelay: 30000,
    logoCrossFadeDuration: 1500,

    incentiveVisibleTime: 20000,
    fixedIncentivePercent: 0,

    showRed: () => {
        flagRenderer.setNextRunData();
        if (typeof oengus.nextRun === 'undefined') {
            flagRenderer.showGreen();
            return;
        }

        anime({
            targets: '#flag-piece-red>.long-flag',
            translateX: flagRenderer.flagOutExtent,
            skew: flagRenderer.skewProperties,
            easing: 'easeOutQuint',
            duration: flagRenderer.linearOutDuration,
            complete: flagRenderer.showNextRun,
        });
    },

    showNextRun: () => {
        anime({
            targets: flagRenderer.nextRunElement,
            skew: flagRenderer.skewPropertiesNegative,
            opacity: 1,
            translateX: 10,
            easing: 'linear',
            duration: 400,
            complete: function () {
                flagRenderer.hideNextGameIn(10);
            },
        });
    },

    showArrow1: () => {
        if (flagRenderer.futureRuns.length < 2) {
            flagRenderer.showFutureRuns();
            return;
        }

        anime({
            targets: '#arrow-1',
            opacity: 1,
            easing: 'linear',
            duration: 200,
            complete: function () {
                if (flagRenderer.futureRuns.length > 2) flagRenderer.showArrow2();
                else flagRenderer.showFutureRuns();
            },
        });
    },

    showArrow2: () => {
        anime({
            targets: '#arrow-2',
            opacity: 1,
            easing: 'linear',
            duration: 200,
            complete: flagRenderer.showFutureRuns,
        });
    },

    hideArrow1: () => {
        anime({
            targets: '#arrow-1',
            opacity: 0,
            easing: 'linear',
            duration: 200,
        });
    },

    hideArrow2: () => {
        anime({
            targets: '#arrow-2',
            opacity: 0,
            easing: 'linear',
            duration: 200,
        });
    },

    showFutureRuns: () => {
        const futureRuns = document.querySelectorAll('#future-runs>.future-run');
        let t = anime.timeline();
        futureRuns.forEach(function (runEl, index) {
            t.add({
                targets: runEl.querySelector('.game-time'),
                opacity: 1,
                translateX: 10,
                easing: 'linear',
                duration: 200,
                complete: function () {
                    anime({
                        // hides name if alternate mode is on
                        targets: runEl.querySelector('.game-name.alternate'),
                        opacity: 0,
                        easing: 'linear',
                        duration: flagRenderer.futureRunAnimationDuration,
                        direction: 'alternate',
                        loop: 6,
                        delay: flagRenderer.futureRunAnimationDelay / 12,
                        endDelay: flagRenderer.futureRunAnimationDelay / 12,
                    });
                    anime({
                        // shows category if alternate mode is on
                        targets: runEl.querySelector('.game-category.alternate'),
                        opacity: 1,
                        easing: 'linear',
                        duration: flagRenderer.futureRunAnimationDuration,
                        direction: 'alternate',
                        loop: 6,
                        delay: flagRenderer.futureRunAnimationDelay / 12,
                        endDelay: flagRenderer.futureRunAnimationDelay / 12,
                    });

                    anime({
                        targets: runEl.querySelector('.game-time'),
                        opacity: 0,
                        translateX: 0,
                        easing: 'linear',
                        duration: 200,
                        delay: flagRenderer.futureRunAnimationDelay,
                    });
                }
            })
                .add({
                    targets: runEl.querySelector('.game-data'),
                    opacity: 1,
                    translateX: 10,
                    easing: 'linear',
                    duration: 200,
                    complete: function () {
                        anime({
                            targets: runEl.querySelector('.game-data'),
                            opacity: 0,
                            translateX: 0,
                            easing: 'linear',
                            duration: 200,
                            delay: flagRenderer.futureRunAnimationDelay,
                            complete: function () {
                                if (index === futureRuns.length - 1) {
                                    flagRenderer.hideArrow1();
                                    flagRenderer.hideArrow2();
                                    if (window.stopLoop)
                                        return;

                                    flagRenderer.showGreen();
                                }
                            }
                        });
                    }
                });
        });
    },

    hideNextGame: () => {
        anime({
            targets: flagRenderer.nextRunElement,
            skew: flagRenderer.skewPropertiesNegative,
            opacity: 0,
            translateX: 10,
            easing: 'linear',
            duration: 400,
            complete: flagRenderer.resetNextGamePosition
        });
    },

    resetNextGamePosition: () => {
        anime({
            targets: flagRenderer.nextRunElement,
            skew: flagRenderer.skewPropertiesNegative,
            opacity: 0,
            translateX: 0,
            easing: 'linear',
            duration: 400,
            complete: function () {
                if (window.stopLoop)
                    return;

                flagRenderer.showWhiteIn(0.4);
            }
        });
    },

    hideNextGameIn: (seconds) => {
        setTimeout(
            flagRenderer.hideNextGame,
            seconds * 1000
        );
    },

    showWhiteIn: (seconds) => {
        setTimeout(
            flagRenderer.showWhite,
            seconds * 1000
        );
    },

    setNextRunData: () => {
        const gameNameEl = document.querySelector('#flag-piece-red>.long-flag>#next-run>.game-name');
        gameNameEl.innerHTML = oengus.nextRun?.name ?? 'N/A';

        const gameCategoryEl = document.querySelector('#flag-piece-red>.long-flag>#next-run>.game-category');
        gameCategoryEl.innerHTML = oengus.nextRun?.submission?.category ?? '';

        const gameRunnerEl = document.querySelector('#flag-piece-red>.long-flag>#next-run>.game-runner>.game-runner-name');
        const runners = oengus.nextRun?.submission?.runners?.map(r => r.user?.name) ?? [];
        const runnerFormattedString = runners.join(' <span class="no-hl">e</span> ');
        gameRunnerEl.innerHTML = runnerFormattedString;

        const gameRunnerParentEl = document.querySelector('#flag-piece-red>.long-flag>#next-run>.game-runner');
        if (runnerFormattedString === '') {
            gameRunnerParentEl.classList.add('hide');
        } else {
            gameRunnerParentEl.classList.remove('hide');
        }
    },

    getWaitTime: (dateTo) => {
        let now = oengus.getNow();

        if (dateTo === undefined) {
            return {
                label: '',
                duration: ''
            };
        }

        if (now.date() !== dayjs(dateTo).date()) {
            return {
                label: '',
                duration: 'DOMANI'
            };
        }

        let differenceInHoursRound = dayjs(dateTo).diff(now, "hours");
        let differenceInHours = dayjs(dateTo).diff(now, "hours", true);
        const minuteRemainder = differenceInHours % differenceInHoursRound || differenceInHours % 1;

        // >= 30M "IN MENO DI" H+1 || < 30M "FRA CIRCA"
        const moreThanHalfHour = minuteRemainder >= 0.5;
        let label = '';
        if (differenceInHoursRound > 0) label = moreThanHalfHour ? 'in meno di' : 'fra circa';
        else label = moreThanHalfHour ? 'fra circa' : 'in meno di';

        if (moreThanHalfHour) differenceInHoursRound += 1;

        // console.log(now, dateTo, differenceInHoursRound, differenceInHours, minuteRemainder);
        if (differenceInHoursRound < 1) differenceInHoursRound = 1;

        return {
            label: label,
            duration: dayjs.duration('PT' + differenceInHoursRound + 'H').locale('it').humanize().replace(/' /, "'")
        };
    },

    setFutureRunData: () => {
        flagRenderer.futureRuns = oengus.next5.slice(1, 4);
        const futureRuns = flagRenderer.futureRuns;
        // console.log(futureRuns);
        const runElements = document.querySelectorAll('#future-runs>.future-run');
        runElements.forEach((runElement, i) => {
            const run = futureRuns[i];
            let shouldClear = false;
            if (typeof run === 'undefined') shouldClear = true;
            // console.log(typeof run, run);

            let waitTimeLabel = [];
            const futureRunDurationSelector = runElement.querySelector('.game-time');
            const gameTimeInEl = futureRunDurationSelector.querySelector('.game-time-in');
            if (shouldClear) {
                gameTimeInEl.innerHTML = "";
            } else {
                waitTimeLabel = flagRenderer.getWaitTime(run.date);
                gameTimeInEl.innerHTML = waitTimeLabel.label;
            }

            const gameTimeEstimateEl = futureRunDurationSelector.querySelector('.game-time-estimate');
            if (shouldClear) {
                gameTimeEstimateEl.innerHTML = "";
            } else {
                gameTimeEstimateEl.innerHTML = waitTimeLabel.duration;
            }

            const futureRunSelector = runElement.querySelector('.game-data');

            const gameNameEl = futureRunSelector.querySelector('.game-name');
            if (shouldClear) {
                gameNameEl.innerHTML = "";
            } else {
                gameNameEl.innerHTML = run.name ?? 'N/A';
            }

            const gameCategoryEl = futureRunSelector.querySelector('.game-category');
            const categoryName = run?.submission?.category ?? '';
            if (shouldClear) {
                gameCategoryEl.innerHTML = "";
            } else {
                gameCategoryEl.innerHTML = categoryName;
            }

            gameNameEl.classList.remove('alternate');
            gameCategoryEl.classList.remove('alternate');
            gameNameEl.style.opacity = "";
            gameCategoryEl.style.opacity = "";

            if (categoryName !== '' && gameNameEl.getBoundingClientRect().top !== gameCategoryEl.getBoundingClientRect().top) {
                gameNameEl.classList.add('alternate');
                gameCategoryEl.classList.add('alternate');
            }

            const gameRunnerNameEl = futureRunSelector.querySelector('.game-runner>.game-runner-name');
            const gameRunnerEl = futureRunSelector.querySelector('.game-runner');
            if (shouldClear) {
                gameRunnerNameEl.innerHTML = "";
                gameRunnerEl.classList.add('hide');
            } else {
                const runners = run.submission?.runners?.map(r => r.user?.name) ?? [];
                const runnerFormattedString = runners.join(' <span class="no-hl">e</span> ');
                gameRunnerNameEl.innerHTML = runnerFormattedString;
                gameRunnerEl.classList.remove('hide');

                if (runnerFormattedString === '') {
                    gameRunnerEl.classList.add('hide');
                }
            }
        });
    },

    showWhite: () => {
        flagRenderer.setFutureRunData();
        if (flagRenderer.futureRuns.length < 1) {
            if (window.stopLoop)
                return;

            flagRenderer.showGreen();
            return;
        }

        anime({
            targets: ['#flag-piece-white>.long-flag', '#flag-piece-red>.long-flag'],
            translateX: flagRenderer.flagOutExtent,
            skew: flagRenderer.skewProperties,
            easing: 'easeOutQuint',
            duration: flagRenderer.linearOutDuration,
            complete: flagRenderer.showArrow1,
        });
    },

    showGreen: () => {
        if (strapi.incentives.length == 0) {
            flagRenderer.clearFlag();
            return;
        }

        anime({
            targets: ['#flag-piece-green>.long-flag', '#flag-piece-white>.long-flag', '#flag-piece-red>.long-flag'],
            translateX: flagRenderer.flagOutExtent,
            skew: flagRenderer.skewProperties,
            easing: 'easeOutQuint',
            duration: flagRenderer.linearOutDuration,
            complete: flagRenderer.showGreenContents
        });
    },

    showGreenContents: () => {
        if (strapi.incentives.length == 0) {
            flagRenderer.clearFlag();
        } else {
            anime({
                targets: '#arrow-incentive',
                translateX: 80,
                opacity: 1,
                skew: flagRenderer.skewPropertiesNegative,
                easing: 'linear',
                duration: flagRenderer.futureRunAnimationDuration,
                complete: flagRenderer.startIncentivesLoop,
            });
        }
    },

    startIncentivesLoop: () => {
        strapi.incentives.forEach(function (incentive, i) {
            setTimeout(function () {
                flagRenderer.populateAndShowIncentiveData(incentive);
            }, flagRenderer.incentiveVisibleTime * i);
        });
        setTimeout(function () {
            flagRenderer.hideGreenContents();
        }, flagRenderer.incentiveVisibleTime * (strapi.incentives.length));
    },

    hideGreenContents: () => {
        anime({
            targets: '#arrow-incentive',
            translateX: 100,
            opacity: 0,
            skew: flagRenderer.skewPropertiesNegative,
            easing: 'linear',
            duration: flagRenderer.futureRunAnimationDuration,
            complete: flagRenderer.clearFlag,
        });
    },

    populateAndShowIncentiveData: (incentive) => {
        flagRenderer.showIncentiveContainer(incentive.type);
        if (incentive.type === 'option')
            flagRenderer.populateAndShowOptionIncentiveData(incentive);
        else if (incentive.type === 'fixed')
            flagRenderer.populateAndShowFixedIncentiveData(incentive);
    },

    showIncentiveContainer: (type) => {
        const fixedIncentiveContainerElement = document.querySelector('.fixed-data');
        const optionIncentiveContainerElement = document.querySelector('.bar-data');
        const isFixed = (type === 'fixed');

        fixedIncentiveContainerElement.style.display = isFixed ? 'flex' : 'none';
        optionIncentiveContainerElement.style.display = isFixed ? 'none' : 'flex';
    },

    showIncentiveGameData: (incentive) => {
        const incentiveElement = document.querySelector('.incentive-data');
        const incentiveGameNameElement = incentiveElement.querySelector('.game-name');
        const incentiveGameCategoryElement = incentiveElement.querySelector('.game-category');

        incentiveGameNameElement.innerHTML = incentive.gameName;
        incentiveGameCategoryElement.innerHTML = incentive.name;

        anime({
            targets: '.incentive-data .game-data',
            translateX: 0,
            opacity: 1,
            easing: 'easeOutSine',
            duration: flagRenderer.futureRunAnimationDuration,
        });

        setTimeout(function () {
            anime({
                targets: '.incentive-data .game-data',
                translateX: 20,
                opacity: 0,
                easing: 'easeOutSine',
                duration: flagRenderer.futureRunAnimationDuration,
                complete: function () {
                    anime({
                        targets: '.incentive-data .game-data',
                        translateX: -20,
                        opacity: 0,
                        easing: 'steps(1)',
                        duration: flagRenderer.futureRunAnimationDuration,
                    });
                }
            });
        }, flagRenderer.incentiveVisibleTime - (flagRenderer.futureRunAnimationDuration * 3));
    },

    populateAndShowFixedIncentiveData: (incentive) => {
        if (incentive.type !== 'fixed') return;

        flagRenderer.showIncentiveGameData(incentive);

        let formattedTotal = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(incentive.total ?? 0);
        let formattedGoal = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(incentive.goal ?? 0);

        console.log(incentive, formattedTotal, formattedGoal);
        const donationTotalsNumberElement = document.querySelector('.incentive-data .fixed-data .donation-totals-number');
        donationTotalsNumberElement.innerHTML = formattedTotal + " / " + formattedGoal;
        const percent = incentive.total * 100 / incentive.goal;
        console.log(incentive.total * 100 / incentive.goal, percent);
        flagRenderer.moveFixedIncentiveBar(percent);
    },

    moveFixedIncentiveBar: (percent) => {
        if (percent > 100) percent = 100;
        else if (percent < 0) percent = 0;

        const minMargin = -705;
        const maxMargin = 0;
        const marginDelta = minMargin - maxMargin;
        const marginPercent = marginDelta * percent / 100;
        const margin = minMargin - marginPercent;

        flagRenderer.fixedIncentivePercent = 0;
        const percentElement = document.querySelector('.incentive-data .fixed-data .donation-bar-progressbar .percent');
        const hideDelay = flagRenderer.incentiveVisibleTime - 1300;
        const duration = flagRenderer.futureRunAnimationDuration * 4;
        percentElement.innerHTML = "0%";
        anime({
            targets: '.incentive-data .fixed-data .donation-bar-progressbar',
            translateX: [
                { value: minMargin, duration: 10 },
            ],
            easing: 'steps(1)',
            complete: function () {
                anime({
                    targets: '.incentive-data .fixed-data .donation-totals',
                    translateY: [
                        { value: 0 },
                        { value: -35, delay: hideDelay }
                    ],
                    opacity: [
                        { value: 1 },
                        { value: 0, delay: hideDelay }
                    ],
                    easing: 'easeOutQuint',
                    duration: duration,
                });
                anime({
                    targets: '.incentive-data .fixed-data .donation-bar-container',
                    translateY: [
                        { value: 0 },
                        { value: 40, delay: hideDelay }
                    ],
                    opacity: [
                        { value: 1 },
                        { value: 0, delay: hideDelay }
                    ],
                    easing: 'easeOutQuint',
                    duration: duration,
                });
                anime({
                    targets: '.incentive-data .fixed-data .donation-bar-progressbar',
                    translateX: [
                        { value: margin, duration: percent * 50 },
                    ],
                    easing: 'easeOutCirc',
                });
                anime({
                    targets: flagRenderer,
                    fixedIncentivePercent: percent,
                    easing: 'easeOutCirc',
                    duration: percent * 50,
                    update: function () {
                        percentElement.innerHTML = parseInt(flagRenderer.fixedIncentivePercent) + '%';
                    },
                })
            }
        });
    },

    populateAndShowOptionIncentiveData: (incentive) => {
        if (incentive.type !== 'option') return;

        flagRenderer.showIncentiveGameData(incentive);

        const noOptionsElement = document.querySelector('.incentive-data .bar-data .no-options');

        let options = incentive.options;
        if (options.length === 0) {
            noOptionsElement.style.display = 'flex';
            setTimeout(function () {
                anime({
                    targets: noOptionsElement,
                    easing: 'easeOutQuint',
                    opacity: [
                        { value: 1 },
                        { value: 0, delay: flagRenderer.incentiveVisibleTime - 1000 },
                    ],
                    translateX: [
                        { value: 0 },
                        { value: 40, delay: flagRenderer.incentiveVisibleTime - 1000 },
                        { value: -40, delay: flagRenderer.futureRunAnimationDuration },
                    ],
                    duration: flagRenderer.futureRunAnimationDuration * 3,
                }, 1000);
            });
        } else {
            noOptionsElement.style.display = 'none';
            const barPopupWait = 80;
            const percents = [100, 85, 75];
            const allEmpty = options.filter(o => o.total == 0).length === options.length;
            for (let i in options) {
                i = Number(i);
                if (i >= 3)
                    break;

                let option = options[i];
                let indexFromOne = i + 1;
                flagRenderer.populateOptionIncentiveBarData(indexFromOne, option)
                setTimeout(function () {
                    flagRenderer.showOptionIncentiveBar(indexFromOne, allEmpty ? 75 : percents[i]);
                }, barPopupWait * indexFromOne);
            }
        }
    },

    populateOptionIncentiveBarData: (i, childIncentive) => {
        const incentiveElement = document.querySelector('.incentive-data');
        const incentiveBarElement = incentiveElement.querySelector('.bar-' + i);
        const incentiveGameNameElement = incentiveBarElement.querySelector('.bar-name');
        const incentiveGameTotalElement = incentiveBarElement.querySelector('.bar-total');

        incentiveGameNameElement.innerHTML = childIncentive.name;
        let formattedNumber = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(childIncentive.total);
        incentiveGameTotalElement.innerHTML = formattedNumber;
    },

    showOptionIncentiveBar: (i, percent) => {
        const minMargin = -90;
        const maxMargin = -15;
        const marginDelta = minMargin - maxMargin;
        const marginPercent = marginDelta * percent / 100;
        const margin = minMargin - marginPercent;

        anime({
            targets: '.incentive-data .bar-' + i,
            marginBottom: margin,
            easing: 'easeOutSine',
            duration: flagRenderer.futureRunAnimationDuration,
            delay: flagRenderer.futureRunAnimationDuration,
            complete: function () {
                setTimeout(function () {
                    anime({
                        targets: '.incentive-data .bar-' + i,
                        marginBottom: minMargin,
                        easing: 'easeOutSine',
                        duration: flagRenderer.futureRunAnimationDuration,
                    });
                }, flagRenderer.incentiveVisibleTime - 1000);
            }
        });
    },

    clearFlag: () => {
        anime({
            targets: '#arrow-incentive',
            translateX: 0,
            opacity: 0,
            skew: flagRenderer.skewPropertiesNegative,
            easing: 'linear',
            duration: flagRenderer.futureRunAnimationDuration,
        });
        anime.timeline()
            .add({
                targets: '#flag-piece-green>.long-flag',
                translateX: flagRenderer.flagOutExtent + 20,
                skew: flagRenderer.skewProperties,
                easing: 'easeOutCubic',
                duration: flagRenderer.linearOutDuration * 2,
            })
            .add({
                targets: '#flag-piece-green>.long-flag',
                translateX: flagRenderer.flagOutExtent,
                skew: flagRenderer.skewProperties,
                easing: 'easeInCubic',
                duration: 750 * 0.8,
            })
            .add({
                targets: '#flag-piece-red>.long-flag',
                translateX: flagRenderer.flagOutExtent,
                skew: flagRenderer.skewProperties,
                duration: 1 * 0.8,
            }, '-=750')
            .add({
                targets: '#flag-piece-white>.long-flag',
                translateX: flagRenderer.flagOutExtent,
                skew: flagRenderer.skewProperties,
                duration: 1 * 0.8,
            }, '-=750')
            .add({
                targets: '.long-flag',
                translateX: 0,
                skew: flagRenderer.skewProperties,
                duration: 2350 * 0.8,
                easing: 'easeOutCubic',
                complete: function () {
                    oengus.refreshSchedule();
                    if (window.stopLoop)
                        return;

                    infoBar.start();
                }
            });
    },

    logoIdNextMapping: {
        'isc-logo': 'guf-tag',
        'guf-tag': 'isc-logo',
    },

    fadeInLogo: (logoId) => {
        const logoElementId = logoId;
        const nextLogoId = flagRenderer.logoIdNextMapping[logoId];
        anime({
            targets: ['#' + logoElementId, '#' + nextLogoId],
            opacity: function (el, i) {
                const opacityValue = i === 0 ? 1 : 0;
                return opacityValue;
            },
            easing: 'easeInOutQuad',
            duration: flagRenderer.logoCrossFadeDuration,
            endDelay: flagRenderer.logoCrossFadeDelay,
            complete: function () {
                flagRenderer.fadeInLogo(nextLogoId);
            }
        });
    }
}

flagRenderer.fadeInLogo('isc-logo');

window.flagRenderer = flagRenderer;
export { flagRenderer };